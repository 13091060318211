<template>
    <div>
        <v-row v-if="!$parent.$parent.get_permission('read')">
            <v-col>
                <AccessMessage />
            </v-col>
        </v-row>
        <v-row v-else>
            <!-- menu superior -->
            <v-col cols="12">
                <v-btn v-if="$parent.$parent.get_permission('edit')" class="ma-1" large solo depressed color="secondary" :to="{ name: 'dashboard_liderazgos_edit', params: $router.params }">
                    <v-icon class="mr-2">edit</v-icon>
                    <span class="subtitle-2">Editar</span>
                </v-btn>
            </v-col>
            <!-- general -->
            <v-col cols="12" md="6" lg="4">
                <DashCard title="General">
                    <v-row>
                        <v-col cols="12">
                            <v-label>ID SAP-EHS</v-label>
                            <v-text-field hide-details dense solo outlined flat readonly v-model="item.cod_sapehs" />
                        </v-col>
                        <v-col cols="12">
                            <v-label>Ejecución</v-label>
                            <v-text-field hide-details dense solo outlined flat readonly :value="item.fecha_ejecucion | moment('L')" prepend-inner-icon="event" />
                        </v-col>
                        <v-col cols="12">
                            <v-label>Area</v-label>
                            <v-text-field hide-details dense solo outlined flat readonly :value="area.nombre" prepend-inner-icon="room" />
                        </v-col>
                        <v-col cols="12">
                            <v-label>Estado</v-label>
                            <v-flex>
                                <v-chip class="ma-1" dark large label :color="item.estado_actividad | color_estado('liderazgo')">
                                    <v-icon class="pr-2">flag</v-icon>
                                    <span>{{ item.estado_actividad | estado('liderazgo') }}</span>
                                </v-chip>
                                <v-chip dark large label :color="item.estado_tiempo | color_estado('tiempo')">
                                    <v-icon large>av_timer</v-icon>
                                    <span class="px-2">{{ item.estado_tiempo | estado('tiempo') }}</span>
                                </v-chip>
                            </v-flex>
                        </v-col>
                    </v-row>
                </DashCard>
            </v-col>
            <!-- descripción -->
            <v-col cols="12" md="6" lg="8">
                <DashCard title="Descripción">
                    <v-row>
                        <v-col cols="12">
                            <v-label>Actividad</v-label>
                            <v-textarea hide-details dense solo outlined flat readonly v-model="item.actividad" />
                        </v-col>
                        <v-col cols="12">
                            <v-label>Coordinador encargado</v-label>
                            <v-list>
                                <PersonaListItem v-model="item.coordinador_inicial" />
                            </v-list>
                        </v-col>
                    </v-row>
                </DashCard>
            </v-col>
            <!-- tarea -->
            <v-col cols="12">
                <DashCard title="Tareas" body-class="pa-0">
                    <GestorTareas v-model="item.tareas" model-type="liderazgos" :read-only="!$parent.$parent.get_permission('edit')" @tarea-change="reload" />
                </DashCard>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import DashCard from '@/shared/components/DashCard'
import PersonaListItem from '@/dash/components/PersonaListItem'
import GestorTareas from '@/dash/components/GestorTareas'
import AccessMessage from '@/dash/components/AccessMessage'
import { axiosHelper, color_estados, messages } from '@/helpers'
import moment from 'moment'

export default {
    components: {
        DashCard,
        PersonaListItem,
        GestorTareas,
        AccessMessage
    },
    data: () => ({
        item: {},
        title: {
            text: "Compromisos seguridad",
            icon: "inbox"
        }
    }),
    computed: {
        area() {
            return this.item.area || {};
        }
    },
    methods: {
        reload() {
            this.$parent.$parent.reloadComponent();
        }
    },
    mounted() {
        this.$emit('title', this.title);

        if(!this.$parent.$parent.get_permission('read')) return;

        axiosHelper.get('liderazgos/' + this.$route.params.id + '/')
            .then(response => {
                this.item = response.data;
                this.title.text = "Compromisos seguridad " + this.item.id + " - " + this.item.cod_sapehs;
            });
    }
}
</script>